
<style lang="sass" scoped>
.link
  color: #777
  // font-weight: bold
  &:hover
    text-decoration: none
.router-link-active
  color: #466EC3
  font-weight: bold

.container-property
  // z-index: 1000
  .index-nav
    z-index: 1000
  .container-nav
    position: fixed
    left: 0
    top: 0
    width: 300px
    height: 100vh
    z-index: 10
  //   position: fixed
  //   left: 0
  //   top: 0
  //   width: 70px
  //   height: 100vh
  //   z-index: 1000
  //   background-color: darken(#f8f9fa, 7%)
  //   // border-right: solid 1px darken(#f8f9fa, 5%)

  //   .link
  //     width: 100%
  //     font-size: 12px
  //     font-weight: bold
  //     // color: darken(#f8f9fa, 60%) !important
  //     &.router-link-active
  //       color: #007bff !important
  //     &.active
  //       // background-color: #f8f9fa
  //       // background-color: #fff
  //       // color: #466EC3 !important
  //       // font-weight: 800
  //       // color: #007bff !important

  //     &:hover
  //       // background-color: #f8f9fa
  //       // text-decoration: none
  //       // color: #007bff !important
.block
  a
    color: #fff
    // &:hover
    //   background-color: rgba(255,255,255,0.2)
    //   text-decoration: none
.block-scroll
  overflow: scroll
  height: calc(100vh - 53px - 64px)
.container-customer
  font-size: 12px
  .link
    color: darken(#f8f9fa, 60%)
    font-weight: bold
    line-height: 2rem
    &.active
      color: darken(#f8f9fa, 80%)
      background-color: darken(#f8f9fa, 7%)
      // font-weight: 700
.title
  font-weight: bold
  font-size: 1.5rem
  color: #555
.notification
  position: absolute
  right: 0.5rem
  top: 0.5rem
  // right: 3px
  // top: 3px
  z-index: 1000
  font-size: 12px
  .count
    // margin-right: -25px
    // float: right
    position: relative
    // top: -10px
    top: 0px
    right: 5px
    font-size: 12px

.link-channel
  a
    font-weight: 300
    &.active
      font-weight: 700
.block
  a
    &:hover
      text-decoration: none
.block-menu
  a
    padding: 0.5rem 1rem 0.5rem 1.5rem
    font-size: 12px
    &:hover
      background-color: rgba(255,255,255,.05)
.block-label
  padding: 0.5rem 1rem 0.5rem 1rem
  opacity: 0.3
  font-size: 11px
  border-top: solid 1px rgba(0,0,0,.4)
.container-right
  margin-left: 300px
  min-height: 100vh
.container-search
  height: 53px
.block-search
  .form-control
    opacity: 0.5
    border: 0
    box-shadow: none
    font-size: 12px
    color: #fff
    padding: 1rem
    &:focus, &:hover
      opacity: 1

  .block-icon
    opacity: 0.5

.block-menu
  .router-link-active
    background-color: rgba(255,255,255,.1)
.btn-more
  background-color: rgba(255,255,255,0)
  &:active
  &:hover
    background-color: rgba(255,255,255,.1) !important
    color: rgba(255,255,255,.9) !important

.sidebar
  // background-color: rgb(247, 246, 243)
  background-color: #f8f9fa
  color: mix(rgb(247, 246, 243), #000, 30%)
  width: 300px
  height: 100vh
  .header
    // height: 120px
    padding-bottom: 5px
    border-bottom: solid 1px darken(#f8f9fa, 5%) !important
    .workspace
      padding: 0 0 0 0.5rem
      font-weight: bold
      // color: mix(rgb(247, 246, 243), #000, 50%)
      // color: mix(#007bff, #000, 30%)
      color: mix(darken(#007bff, 20%), #888, 50%)
      height: 50px
      line-height: 50px
      &:hover
        background-color: rgba(0,0,0,.03)
    .link
      color: mix(darken(#f8f9fa, 30%), #000, 70%)
      font-size: 14px
      &:hover
        background-color: rgba(0,0,0,.03)
  .menu
    // height: calc(100vh - 100px)
    overflow: scroll
    .label
      // color: mix(rgb(247, 246, 243), #000, 70%)
      color: darken(#f8f9fa, 40%)
      opacity: 0.5
      font-size: 12px
    .link
      color: mix(darken(#f8f9fa, 30%), #000, 70%)
      font-size: 14px
      &:hover
        background-color: rgba(0,0,0,.03)
    .btn-add-project
      opacity: 0
      // background-color: #fff
      border: solid 1px #d4d4d4
      padding: 2px 0.5rem
      &:hover
        background-color: #fff !important
    .btn-add-row
      opacity: 0
      // background-color: #fff
      border: solid 1px #d4d4d4
      padding: 2px 0.5rem
      // &:hover
      //   opacity: 1
      //   background-color: #fff !important
  .menu-project
    &:hover
      .btn-add-project
        opacity: 1
  .menu-pipeline
    &:hover
      .btn-add-project
        opacity: 1

.content
  .menu-bar
    height: 40px
  .frame
    width: calc(100vw - 300px)
    height: calc(100vh - 40px)
    overflow: scroll

.block-draggable
  &.ghost
    opacity: 0 !important
  .handle-grip
    position: relative
    left: 0px
    top: 0px
    padding-right: 5px
    float: left
    cursor: move
    opacity: 0.5
    // transition: opacity ease-in-out 300ms
  &:hover
    cursor: pointer
    background-color: rgba(0,0,0,.03)
    .handle-grip
      opacity: 0.5 !important
      // transition: opacity ease-in-out 300ms
    .btn-add-row
      opacity: 1
      // background-color: #fff !important
    &.router-link-active
      background-color: rgba(0,0,0,.07)
  &.router-link-active
    background-color: rgba(0,0,0,.03)
  // a
  color: mix(darken(#f8f9fa, 30%), #000, 70%)
  font-size: 14px
  font-weight: bold
  &:hover
    text-decoration: none
    // background-color: rgba(0,0,0,.03)
  // a
  //   color: mix(darken(#f8f9fa, 30%), #000, 70%)
  //   font-size: 14px
  //   font-weight: bold
  //   &:hover
  //     text-decoration: none
  //     // background-color: rgba(0,0,0,.03)

.block-project
  padding-left: 1rem !important

.btn-add-row

  &:hover
    opacity: 1
    background-color: #fff !important

.fav
  .btn-add-row
    margin-top: -2px
    margin-right: 0px

</style>
<template lang="pug">
.d-flex
  .sidebar.d-flex.align-items-start.flex-column.d-print-none
    .header.w-100

      //- button.btn.btn-block.text-left.workspace.rounded-0#opt_workspace()
      router-link.btn.btn-block.text-left.workspace.rounded-0(:to='{name: "contact.today"}')
        //- b-icon-files(style='font-size: 14px; margin: 0 7px -1px 4px')
        | {{property.name}}
        button.btn.btn-default#opt_workspace.float-right.text-muted.opacity-50.mt-1
          b-icon-three-dots
      //- button.btn.btn-default.btn-sm.float-right(style='position: relative; right: 0px; top: -40px') 설정
      //- b-popover(ref='opt_workspace' target='opt_workspace' triggers='click blur' no-fade placement='bottom' custom-class='popover-dropdown' :offset='0')
      b-popover(ref='opt_workspace' target='opt_workspace' triggers='click blur' no-fade placement='bottom' custom-class='popover-dropdown' :offset='0')
        router-link.link(:to='{name: "workspace.list"}' custom v-slot="{href, navigate}")
          a.link.text-left.d-block.text-dark(ref='dropdown_focus' :href='href' @click='track("클릭_워크스페이스_이동"); navigate($event)') 다른 워크스페이스 이동
        router-link.link(to='/logout' custom v-slot="{href, navigate}")
          a.link.text-left.d-block.text-dark(:href='href' @click='track("클릭_로그아웃"); navigate($event)') 로그아웃
        //- .p-2.flex-fill
        //-   strong {{property.name}}
        //- .p-2
        //-   router-link.btn.btn-default.btn-sm.p-1(:to='{name: "workspace.list"}') 설정
      router-link.link(:to='{name: "contact.view"}' custom v-slot="{href, navigate}")
        a.d-block.px-2.py-1.link.w-100.block-draggable.block-project(:href='href' @click='track("클릭_연락처"); navigate($event)')
          b-icon-person-fill.mr-2.opacity-50
          | 연락처
      router-link.link(:to='{name: "calendar.view"}' custom v-slot="{href, navigate}")
        a.d-block.px-2.py-1.link.w-100.block-draggable.block-project(:href='href' @click='navigate($event)')
          b-icon-calendar3.mr-2.opacity-50
          | 달력
      a.d-block.px-2.py-1.link.w-100.block-draggable.block-project(@click.prevent='$modal.show("setting"); track("클릭_설정")')
        b-icon-gear-fill.mr-2.opacity-50
        | 설정

      modal(name='setting' width='780' transition='filter' :shiftY='0.2' :height='`auto`' :scrollable='true')
        setting
    .menu.flex-column.w-100
      //- .pb-2
      //- strong.d-block.px-2.py-1.label 즐겨찾기
      //- router-link.d-block.px-2.py-1.link.w-100(:to='{name: "ListView", params: {list_id: 1}}') 프로젝트 1

      //- template(v-if='$store.state.property && $store.state.property.menu_config && $store.state.property.menu_config.blocks.length')
        pre {{$store.state.property.menu_config}}
        //- draggable(:class='{dragging: drag}' v-model='$store.state.property.menu_config.blocks' handle='.handle' v-bind='drag_options' @start='drag = true' @end='drag_end')
          transition-group(type='transition' :name="!drag ? 'flip-list' : null")
            router-link(
              v-for='block in $store.state.property.menu_config.blocks' :key='block.ts'
              v-if='!block.saved_filter_id'
              :to='{name: "document.view", params: {document_id: block.document_id, document_name: $options.filters.encodeReadableText(block.name)}, query: {v: block.saved_filter_id}}'
              v-slot='{href, navigate, isActive}'
              custom
            )
              a.d-block.px-2.py-1.w-100.block-draggable.block-project.handle(
                @click='track("클릭_즐겨찾기", {name: block.name, key: block.key}); navigate($event);' :href='href'
                :class="[isActive && 'router-link-active']"
              )
                span.handle-grip: b-icon-hash
                | {{block.name}}
            //- router-link(
            //-   v-for='block in $store.state.property.menu_config.blocks' :key='block.ts'
            //-   v-if='block.saved_filter_id'
            //-   :to='{name: "document.view", params: {document_id: block.document_id, document_name: $options.filters.encodeReadableText(block.name), saved_filter_id: block.saved_filter_id}}'
            //-   v-slot='{href, navigate, isActive}'
            //-   custom
            //- )
            //-   a.d-block.px-2.py-1.w-100.block-draggable.block-project.handle(
            //-     @click='navigate' :href='href'
            //-     :class="[isActive && 'router-link-active']"
            //-   )
            //-     span.handle-grip: b-icon-hash
            //-     | {{block.name}}

            //- .d-block.px-2.py-1.w-100.block-draggable.handle.fav(v-for='block in $store.state.property.menu_config.blocks' :key='block.ts')
              span.handle-grip: b-icon-hash
              //- span.handle-grip: b-icon-grip-horizontal
              //- span.handle-grip(v-if='block.saved_filter_id'): b-icon-hash
              //- span.handle-grip(v-else): b-icon-dash
              //- span.handle-grip(v-else): b-icon-play-fill
              //- span.handle-grip(v-else): b-icon-play-fill
              router-link.d-block(:to='get_link_href(block)') {{block.name}}
                //- button.btn.btn-default.btn-sm.btn-add-row.float-right(type='button' @click='') 추가

      .pb-2.clearfix
      //- .d-flex(v-if='env.NODE_ENV != "production" ')
      .menu-pipeline
        .d-flex
          .flex-fill
            strong.d-block.px-2.py-1.label 파이프라인
          div
            button.btn.btn-default.btn-sm.mr-1.btn-add-project(type='button' @click='add_pipeline();' :disabled='adding') 추가
        .clearfix
        draggable(:class='{dragging: pipeline_drag}' v-model='$store.state.pipelines' handle='.handle' v-bind='pipeline_drag_options' @start='pipeline_drag = true' @end='pipeline_drag_end')
          transition-group(type='transition' :name="!project_drag ? 'flip-list' : null")
            router-link.clearfix(
              v-for='block in $store.state.pipelines' :key='block.id'
              :to='{name: "pipeline.view", params: {pipeline_id: block.id, pipeline_name: $options.filters.encodeReadableText(block.name)}}'
              v-slot='{href, navigate, isActive}'
              custom
            )
              a.d-block.px-2.py-1.w-100.block-draggable.block-project.handle(
                @click='navigate($event)' :href='href'
                :class="[isActive && 'router-link-active']"
              )
                span.handle-grip
                | {{block.name}}
                span.float-right.opacity-50(v-if='block.config && block.config.stat && block.config.stat.count_status')
                  span.pl-1(v-for='count in block.config.stat.count_status') {{count.count}}
      .pb-2.clearfix
      .menu-project
        .d-flex
          .flex-fill
            strong.d-block.px-2.py-1.label 프로젝트
          div
            button.btn.btn-default.btn-sm.mr-1.btn-add-project(type='button' @click='add_project(); track("클릭_프로젝트추가", {display:"상단"})' :disabled='adding') 추가
        .clearfix
        draggable(:class='{dragging: project_drag}' v-model='$store.state.documents' handle='.handle' v-bind='project_drag_options' @start='project_drag = true' @end='project_drag_end')
          transition-group(type='transition' :name="!project_drag ? 'flip-list' : null")
            router-link(
              v-for='block in $store.state.documents' :key='block.id'
              :to='{name: "document.view", params: {document_id: block.id, document_name: $options.filters.encodeReadableText(block.name)}}'
              v-slot='{href, navigate, isActive}'
              custom
            )
              a.d-block.px-2.py-1.w-100.block-draggable.block-project.handle(
                @click='track("클릭_프로젝트", {name: block.name, id: block.id}); navigate($event)' :href='href'
                :class="[isActive && 'router-link-active']"
              )
                span.handle-grip
                | {{block.name}}

          //- span.handle-grip b-icon-play-fill

          //- router-link.d-block(:to='{name: "document.view", params: {document_id: block.id, document_name: $options.filters.encodeReadableText(block.name)}}') {{block.name}}
          //- button.btn.btn-default.btn-sm.mr-1.btn-add-row.float-right(type='button' @click='') 추가
        .text-left.p-2
          button.btn.btn-default.btn-sm.btn-add-project(type='button' @click='add_project(); track("클릭_프로젝트추가", {display:"하단"});' :disabled='adding') 새 목록 추가
      .pb-4
  .content.d-flex.flex-grow-1.flex-column
    router-view

  //- .container-nav.bg-dark.d-print-none
    .block.block-header.text-white.py-2.px-2.clearfix(style='background-color: rgba(255,255,255,.05);')
      router-link.p-2.float-left(:to='`/property/${property_id}/activity`' style='font-weight: normal; color: #fff'): strong {{property.name}}

      span.float-right
        dropdown(:active='dropdown_account_active')
          template(slot='toggler')
            button.btn.btn-dark.btn-sm.border-0.btn-more(type='button' style='font-size: 12px; font-weight: 700; padding: 9px 13px; color: rgba(255,255,255,.3); margin-top: 0px')
              | 계정
              //- b-icon-chevron-down(style='font-weight: bold')

          dropdown-content
            //- dropdown-block.mt-1
              input.form-control.form-control-sm.form-dropdown(type='text' value='목록 이름')
            router-link.link-dropdown.text-left.p-2.d-block.text-dark(to='/property' exact) 다른 workspace 이동
            router-link.link-dropdown.text-left.p-2.d-block.text-dark(to='/logout') 로그아웃
            //- .border-top(style='opacity: 0.3')
            //- router-link.link-dropdown.text-left.p-2.d-block.text-dark(:to='`/property/${property_id}/manage`' @click.exact='click_dropdown_account_active') Workspace 설정
            //- hr.my-2
      //- span.float-right(style='font-size: 12px') 계정
        b-icon-chevron-down.ml-1(style='font-size: 10px')
      //- router-link.p-2.float-right(:to='`/property/${property_id}/activity`') 설정
    .block.text-white.clearfix.block-search
      //- form.clearfix(@submit='search_customer')
        span.float-left.ml-2.p-2.block-icon(style='font-size: 10px;')
          b-icon-search
        span.float-left
          input.form-control.bg-dark.pl-0(type='text' placeholder='고객ID 고객연락처 고객명')


      form.clearfix(@submit='search_all')
        span.float-left.ml-2.p-2.block-icon(style='font-size: 10px;')
          b-icon-search
        span.float-left
          input.form-control.bg-dark.pl-0(type='text' placeholder='고객·내용 검색')
    .block-scroll
      //- pre {{$store.state.property.menu_config}}
      template(v-if='$store.state.property && $store.state.property.menu_config && $store.state.property.menu_config.blocks.length')
        .block.text-white.clearfix.block-label
          strong 즐겨찾기
        draggable(:class='{dragging: drag}' v-model='$store.state.property.menu_config.blocks' handle='.handle' v-bind='drag_options' @start='drag = true' @end='drag_end')
          transition-group(type='transition' :name="!drag ? 'flip-list' : null")
            .block.text-white.clearfix.block-menu.block-draggable.handle(v-for='block in $store.state.property.menu_config.blocks' :key='block.ts')
              span.handle-grip: b-icon-grip-horizontal
              router-link.d-block(:to='get_link_href(block)') {{block.name}}
      .block.text-white.clearfix.block-label
        strong 일반
      .block.text-white.clearfix.block-menu
        router-link.d-block(:to='`/property/${property_id}/activity`') 고객목록

      .block.text-white.clearfix.block-menu
        router-link.d-block(:to='`/property/${property_id}/message/all`') 메시지 발송내역

      .block.text-white.clearfix.block-menu
        router-link.d-block(:to='`/property/${property_id}/manage`') 설정

      .pb-4
      //- .block.text-white.clearfix.block-menu
        router-link.d-block(:to='`/property/${property_id}/message/reply`') 답변도착
          span.badge.badge-primary.float-right 3
    //- .block.text-white.clearfix.block-label
    //-   strong 고객
    //- .block.text-white.clearfix.block-menu
    //-   a.d-block(href='#') 필터1
    //- .block.text-white.clearfix.block-menu
    //-   a.d-block(href='#') 필터2

    //- .block.text-white.clearfix.block-label
    //-   strong 입력
    //- .block.text-white.clearfix.block-menu
    //-   a.d-block(href='#') 이름1
    //-     span.badge.badge-info.float-right SQL
    //- .block.text-white.clearfix.block-menu
    //-   a.d-block(href='#') 이름2
    //-     span.badge.badge-info.float-right SQL
    //- .block.text-white.clearfix.block-menu
    //-   a.d-block(href='#') 이름3
    //-     span.badge.badge-info.float-right SQL


    //- .block.text-white.clearfix.block-label
    //-   //- strong 기타
    //- //- .block.text-white.clearfix.block-menu
    //-   router-link.d-block(:to='`/property/${property_id}/manage`') 설정
    //- .block.text-white.clearfix.block-menu.w-100(style='position: absolute; bottom: 0px')
    //-   //- router-link.d-block(:to='`/property`' active-class='') 다른 workspace 이동
    //-   router-link.d-block(:to='`/property/${property_id}/manage`') 설정
    //- .block.text-white.clearfix.block-menu
      router-link.d-block(:to='`/property/${property_id}/manage`') 알림

  //- .container-right.bg-light(style='height: 100vh; overflow: scroll; -webkit-overflow-scrolling: touch')
    .p-3
      router-view


</template>

<script>

import draggable from 'vuedraggable'

import Dropdown from "@/components/Dropdown";
import DropdownContent from "@/components/DropdownContent";
import DropdownItem from "@/components/DropdownItem";
import DropdownBlock from "@/components/DropdownBlock";

import Setting from "@/components/Setting";

export default {
  name: 'index',
  props: ['property_subdomain'],
  components: {
    Dropdown, DropdownContent, DropdownItem, DropdownBlock,
    Setting,
    draggable,
  },
  computed: {
    session() {
      return this.$store.state.session
    },
    property() {
      return this.$store.state.property
    },
  },
  watch: {
    // '$store.state.property'() {
    //   if (this.$store.state.property?.error) {
    //     alert(this.$store.state.property.error.message)
    //   }
    // },
  },
  data() {
    return {
      loaded: false,

      count: null,
      notification_loaded: false,

      adding: false,

      dropdown_account_active: 1,

      drag: false,

      drag_options: {
        animation: 200,
        ghostClass: 'ghost',
      },

      project_drag: false,

      project_drag_options: {
        animation: 200,
        ghostClass: 'ghost',
      },

      pipeline_drag: false,

      pipeline_drag_options: {
        animation: 200,
        ghostClass: 'ghost',
      },

      env: process.env,
    }
  },
  async mounted() {
    let profile = {}
    try {
      // await this.$store.dispatch('user permissions v2', this.$route.params.property_subdomain)
      const r = await this.$http.get(`/v2/property/${this.$route.params.property_subdomain}/me`)
      if (r?.data?.message != 'ok') throw Error(r?.data?.message || '프로필 가져오기 실패')
      profile = r.data

      const property = await this.$store.dispatch('property v2', this.property_subdomain)

      await this.$store.dispatch('users by id', property.id)
      await this.$store.dispatch('documents', property.id)
      await this.$store.dispatch('pipelines', property.id)

      // if (this.$route.name == 'layout') {
      //   this.$router.push({
      //     name: 'contact.today',
      //   })
      // }
    } catch (error) {
      this.$modal.show('dialog', {
        title: '알림',
        text: error.message,
      })
      if (error.action == 'REDIRECT') {
        if (profile.invited.length) {
          this.$router.push({
            name: 'workspace.accept_invite',
          })
          return
        }
        this.$router.push({
          name: 'workspace.list',
        })
      }
    }
    // await this.$store.dispatch('users by id', this.property_id)
    // await this.$store.dispatch('documents', this.property_id)
    // setTimeout(() => {
    //   this.count = 0
    //   // this.count = 12
    //   this.notification_loaded = true

    if (window.localStorage) {
      window.localStorage['noitaler.latest_property_id'] = this.property_id
      window.localStorage['noitaler.latest_property_subdomain'] = this.property_subdomain
      window.localStorage['noitaler.latest_user_id'] = this.session.id
    }
    // }, 300);
    this.loaded = true
    // this.$modal.show('setting')
    this.track('화면_진입')
  },
  methods: {
    track(name, prop) {
      window.amplitude?.getInstance().logEvent(name, Object.assign({view:"워크스페이스"}, prop))
    },
    async add_project() {
      try {
        this.adding = true
        const r = await this.$http.post(`/v1/property/${this.property.id}/views/documents`, {

        })
        if (r?.data?.message != 'ok') throw new Error('프로젝트 추가 실패')

        await this.$store.dispatch('documents', this.property.id)

        setTimeout(() => {
          this.adding = false

          this.$router.push({
            name: 'document.view',
            params: {
              document_id: r.data.document_id,
              document_name: this.$options.filters.encodeText(r.data.name),
            },
          })

        }, 300);
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
    },
    async add_pipeline() {
      try {
        this.adding = true
        const r = await this.$http.post(`/v1/property/${this.property.id}/views/pipelines`, {

        })
        if (r?.data?.message != 'ok') throw new Error('파이프라인 추가 실패')

        await this.$store.dispatch('pipelines', this.property.id)

        setTimeout(() => {
          this.adding = false

          this.$router.push({
            name: 'pipeline.view',
            params: {
              pipeline_id: r.data.pipeline_id,
              pipeline_name: this.$options.filters.encodeText(r.data.name),
            },
          })

        }, 300);
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
    },
    async drag_end() {
      this.track('드래그_메뉴')
      try {
        this.drag = false
        const menu_config = this.$store.state.property.menu_config
        const r = await this.$http.put(`/v1/property/${this.property_id}/menu_config`, {
          menu_config,
        })
        if (r.data.message != 'ok') throw new Error(r.data.message || '즐겨찾기 저장 실패')
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
    },
    async project_drag_end() {
      this.track('드래그_메뉴')
      try {
        this.project_drag = false
        let i = 0
        for (const document of this.$store.state.documents) {
          const prev_order = document.config.order
          const next_order = i++

          if (next_order !== prev_order) {
            const next_document = Object.assign({}, document, {
              config: Object.assign({}, document.config, {
                order: next_order,
              })
            })
            console.log('doc new order', next_document.name, prev_order, '<>', next_order)
            this.$http.put(`/v1/property/${this.property.id}/views/documents/${next_document.id}`, next_document)
            setTimeout(() => {
              this.$store.dispatch('documents', this.property.id)
            }, 300);
          }
        }
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
    },
    async pipeline_drag_end() {
      try {
        this.pipeline_drag = false
        let i = 0
        for (const pipeline of this.$store.state.pipelines) {
          const prev_order = pipeline.config.order
          const next_order = i++

          if (next_order !== prev_order) {
            const next_pipeline = Object.assign({}, pipeline, {
              config: Object.assign({}, pipeline.config, {
                order: next_order,
              })
            })
            console.log('doc new order', next_pipeline.name, prev_order, '<>', next_order)
            this.$http.put(`/v1/property/${this.property.id}/views/pipelines/${next_pipeline.id}`, next_pipeline)
            setTimeout(() => {
              this.$store.dispatch('documents', this.property.id)
            }, 300);
          }
        }
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
    },
    get_saved_filter_prefix(saved_filter_id) {
      return saved_filter_id ? '-'+saved_filter_id : ''
    },
    get_link_href(block) {
      if (block.saved_filter_id) {
        return `/property/${this.property_id}/customer/${block.document_id}${this.get_saved_filter_prefix(block.saved_filter_id)}`
      } else {
        return `/property/${this.property_id}/customer/${block.document_id}`
      }
    },
    // get_link_label(block) {
    //   const d = this.$store.state.documents_by_id[block.document_id]
    //   return d.name
    // },
    search_all() {

    },
  },
}
</script>
