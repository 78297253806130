var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"sidebar d-flex align-items-start flex-column d-print-none"},[_c('div',{staticClass:"header w-100"},[_c('router-link',{staticClass:"btn btn-block text-left workspace rounded-0",attrs:{"to":{name: "contact.today"}}},[_vm._v(_vm._s(_vm.property.name)),_c('button',{staticClass:"btn btn-default float-right text-muted opacity-50 mt-1",attrs:{"id":"opt_workspace"}},[_c('b-icon-three-dots')],1)]),_c('b-popover',{ref:"opt_workspace",attrs:{"target":"opt_workspace","triggers":"click blur","no-fade":"","placement":"bottom","custom-class":"popover-dropdown","offset":0}},[_c('router-link',{staticClass:"link",attrs:{"to":{name: "workspace.list"},"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
return [_c('a',{ref:"dropdown_focus",staticClass:"link text-left d-block text-dark",attrs:{"href":href},on:{"click":function($event){_vm.track("클릭_워크스페이스_이동"); navigate($event)}}},[_vm._v("다른 워크스페이스 이동")])]}}])}),_c('router-link',{staticClass:"link",attrs:{"to":"/logout","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
return [_c('a',{staticClass:"link text-left d-block text-dark",attrs:{"href":href},on:{"click":function($event){_vm.track("클릭_로그아웃"); navigate($event)}}},[_vm._v("로그아웃")])]}}])})],1),_c('router-link',{staticClass:"link",attrs:{"to":{name: "contact.view"},"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
return [_c('a',{staticClass:"d-block px-2 py-1 link w-100 block-draggable block-project",attrs:{"href":href},on:{"click":function($event){_vm.track("클릭_연락처"); navigate($event)}}},[_c('b-icon-person-fill',{staticClass:"mr-2 opacity-50"}),_vm._v("연락처")],1)]}}])}),_c('router-link',{staticClass:"link",attrs:{"to":{name: "calendar.view"},"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
return [_c('a',{staticClass:"d-block px-2 py-1 link w-100 block-draggable block-project",attrs:{"href":href},on:{"click":function($event){return navigate($event)}}},[_c('b-icon-calendar3',{staticClass:"mr-2 opacity-50"}),_vm._v("달력")],1)]}}])}),_c('a',{staticClass:"d-block px-2 py-1 link w-100 block-draggable block-project",on:{"click":function($event){$event.preventDefault();_vm.$modal.show("setting"); _vm.track("클릭_설정")}}},[_c('b-icon-gear-fill',{staticClass:"mr-2 opacity-50"}),_vm._v("설정")],1),_c('modal',{attrs:{"name":"setting","width":"780","transition":"filter","shiftY":0.2,"height":"auto","scrollable":true}},[_c('setting')],1)],1),_c('div',{staticClass:"menu flex-column w-100"},[_c('div',{staticClass:"pb-2 clearfix"}),_c('div',{staticClass:"menu-pipeline"},[_c('div',{staticClass:"d-flex"},[_vm._m(0),_c('div',[_c('button',{staticClass:"btn btn-default btn-sm mr-1 btn-add-project",attrs:{"type":"button","disabled":_vm.adding},on:{"click":function($event){return _vm.add_pipeline();}}},[_vm._v("추가")])])]),_c('div',{staticClass:"clearfix"}),_c('draggable',_vm._b({class:{dragging: _vm.pipeline_drag},attrs:{"handle":".handle"},on:{"start":function($event){_vm.pipeline_drag = true},"end":_vm.pipeline_drag_end},model:{value:(_vm.$store.state.pipelines),callback:function ($$v) {_vm.$set(_vm.$store.state, "pipelines", $$v)},expression:"$store.state.pipelines"}},'draggable',_vm.pipeline_drag_options,false),[_c('transition-group',{attrs:{"type":"transition","name":!_vm.project_drag ? 'flip-list' : null}},_vm._l((_vm.$store.state.pipelines),function(block){return _c('router-link',{key:block.id,staticClass:"clearfix",attrs:{"to":{name: "pipeline.view", params: {pipeline_id: block.id, pipeline_name: _vm.$options.filters.encodeReadableText(block.name)}},"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('a',{staticClass:"d-block px-2 py-1 w-100 block-draggable block-project handle",class:[isActive && 'router-link-active'],attrs:{"href":href},on:{"click":function($event){return navigate($event)}}},[_c('span',{staticClass:"handle-grip"}),_vm._v(_vm._s(block.name)),(block.config && block.config.stat && block.config.stat.count_status)?_c('span',{staticClass:"float-right opacity-50"},_vm._l((block.config.stat.count_status),function(count){return _c('span',{staticClass:"pl-1"},[_vm._v(_vm._s(count.count))])}),0):_vm._e()])]}}],null,true)})}),1)],1)],1),_c('div',{staticClass:"pb-2 clearfix"}),_c('div',{staticClass:"menu-project"},[_c('div',{staticClass:"d-flex"},[_vm._m(1),_c('div',[_c('button',{staticClass:"btn btn-default btn-sm mr-1 btn-add-project",attrs:{"type":"button","disabled":_vm.adding},on:{"click":function($event){_vm.add_project(); _vm.track("클릭_프로젝트추가", {display:"상단"})}}},[_vm._v("추가")])])]),_c('div',{staticClass:"clearfix"}),_c('draggable',_vm._b({class:{dragging: _vm.project_drag},attrs:{"handle":".handle"},on:{"start":function($event){_vm.project_drag = true},"end":_vm.project_drag_end},model:{value:(_vm.$store.state.documents),callback:function ($$v) {_vm.$set(_vm.$store.state, "documents", $$v)},expression:"$store.state.documents"}},'draggable',_vm.project_drag_options,false),[_c('transition-group',{attrs:{"type":"transition","name":!_vm.project_drag ? 'flip-list' : null}},_vm._l((_vm.$store.state.documents),function(block){return _c('router-link',{key:block.id,attrs:{"to":{name: "document.view", params: {document_id: block.id, document_name: _vm.$options.filters.encodeReadableText(block.name)}},"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('a',{staticClass:"d-block px-2 py-1 w-100 block-draggable block-project handle",class:[isActive && 'router-link-active'],attrs:{"href":href},on:{"click":function($event){_vm.track("클릭_프로젝트", {name: block.name, id: block.id}); navigate($event)}}},[_c('span',{staticClass:"handle-grip"}),_vm._v(_vm._s(block.name))])]}}],null,true)})}),1)],1),_c('div',{staticClass:"text-left p-2"},[_c('button',{staticClass:"btn btn-default btn-sm btn-add-project",attrs:{"type":"button","disabled":_vm.adding},on:{"click":function($event){_vm.add_project(); _vm.track("클릭_프로젝트추가", {display:"하단"});}}},[_vm._v("새 목록 추가")])])],1),_c('div',{staticClass:"pb-4"})])]),_c('div',{staticClass:"content d-flex flex-grow-1 flex-column"},[_c('router-view')],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex-fill"},[_c('strong',{staticClass:"d-block px-2 py-1 label"},[_vm._v("파이프라인")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex-fill"},[_c('strong',{staticClass:"d-block px-2 py-1 label"},[_vm._v("프로젝트")])])}]

export { render, staticRenderFns }