<template>
  <div @click="toggle" v-on-clickaway="away" class="relative" style='position: relative;'>
    <slot name="toggler">
      <button>Toggle</button>
    </slot>
    <slot/>
  </div>
</template>

<script>
import { mixin as clickaway } from "vue-clickaway";

export default {
  name: "Dropdown",
  props: ['active'],
  mixins: [
    clickaway
  ],
  provide() {
    return {
      sharedState: this.sharedState
    };
  },
  data() {
    return {
      sharedState: {
        active: false,
        keep: false,
      }
    };
  },
  watch: {
    active(a, b) {
      setTimeout(() => {
        if (!b && a) return this.away()
        if (a > b) return this.away()
      }, 100)
    },
  },
  methods: {
    toggle() {
      if (this.sharedState.keep) return
      this.sharedState.active = !this.sharedState.active;
    },
    away() {
      this.sharedState.active = false;
      this.sharedState.keep = false;
      console.log('away')
    }
  }
};
</script>
