import { render, staticRenderFns } from "./DropdownBlock.vue?vue&type=template&id=5bc6d849&scoped=true&"
import script from "./DropdownBlock.vue?vue&type=script&lang=js&"
export * from "./DropdownBlock.vue?vue&type=script&lang=js&"
import style0 from "./DropdownBlock.vue?vue&type=style&index=0&id=5bc6d849&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5bc6d849",
  null
  
)

export default component.exports