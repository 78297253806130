<style lang="sass" scoped>
.title
  font-weight: bold
  font-size: 1.5rem
  color: #555
.subtitle
  font-weight: 600
  // font-size: 1.5rem
  color: #555
.body
  // height: 600px
  // width: 450px
.sidebar
  background-color: #f8f9fa
  color: mix(rgb(247, 246, 243), #000, 30%)
  width: 250px
  // height: 100vh
  .link
    color: #555
    font-weight: 600
    &:hover
      background-color: rgba(0,0,0,.03)
      text-decoration: none
    &.active
      background-color: rgba(0,0,0,.03)
      &:hover
        background-color: rgba(0,0,0,.06)
  .fieldset
    color: #555
    font-size: 12px
    font-weight: 600
    opacity: 0.5
.table-member
  font-size: 12px
</style>
<template lang="pug">
.body
  .d-flex
    .sidebar
      .pt-2
      .fieldset.d-block.px-2 Workspace
      a.d-block.p-2.link(href='#' :class='{active: (current_tab == "member")}') 계정 관리
    .content.p-4.w-100
      div.async(v-if='current_tab == "member"' :class='{done: done}')
        h6.subtitle.pb-2 새로운 계정 추가하기
        hr.opacity-50.mt-0
        .p-0
          form.form-inline(@submit.prevent='add_member')
            .form-group
              label.mr-2 이메일 주소
              input.form-control.mr-2(type='email' v-model='new_member.email' placeholder='email@address.com')
            .form-group
              button.btn.btn-primary(type='submit') 추가
        .pt-4
        .pt-4
        h6.subtitle.pb-2 계정 목록
        //- hr.opacity-50.mt-0
        //- pre {{$store.state.users}}
        //- pre {{user_permissions}}
        table.table.table-member.border-bottom
          thead
            tr
              td.py-2.opacity-50 이메일주소
              td.py-2.opacity-50(style='width: 200px') 상태
          tbody
            tr(v-for='u in $store.state.users')
              td
                strong.d-block {{u.name}}
                span.text-muted {{u.email}}
              td
                select.form-control.form-control-sm.p-1(v-model='u.state' @change='save_member_permission(u)')
                  option(value='state_allowed') 이용 가능
                  option(value='state_disabled') 비활성화
                  option(value='state_admin_allowed') 이용 가능 (관리자)
            tr(v-for='p in user_permissions' v-if='!p.user_id')
              td.opacity-50
                strong.d-block 이메일 초대함
                span.text-muted {{p.email}}
              td
                select.form-control.form-control-sm.p-1(v-model='p.state' @change='save_member_permission(p)')
                  option(value='state_invited') 초대함
                  option(value='state_invitation_canceled') 초대 취소함



</template>

<script>

export default {
  name: 'index',
  components: {

  },
  computed: {
    username(id) {
      return this.$store.state.users_by_id[id]?.name || '(이름없음)'
    },
    // session() {
    //   return this.$store.state.session
    // },
    user_permissions() {
      return this.$store.state.user_permissions
    },
    property() {
      return this.$store.state.property
    },
  },
  async mounted() {
    await this.$store.dispatch('user permissions v2', this.property.subdomain)
    this.done = true
  },
  data() {
    return {
      done: false,
      // property: [],

      current_tab: 'member',
      new_member: {
        email: '',
      },
    }
  },
  methods: {
    async load_member_permission() {
      try {
        const r = await this.$http.get(`/v2/property/${this.property.subdomain}/user_permissions`)
        console.log(r)
        if (r?.data?.message != 'ok') throw Error(r?.data?.message || '가져오기 실패')

        this.user_permissions = r.data.rows

        // this.$store.dispatch('users by id', this.property.id)
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
    },
    async add_member() {
      try {
        const r = await this.$http.post(`/v2/property/${this.property.subdomain}/add_user`, {
          email: this.new_member.email
        })
        if (r?.data?.message != 'ok') throw Error(r?.data?.message || '추가 실패')

        this.new_member.email = ''

        // this.$store.dispatch('users by id', this.property.id)
        this.$store.dispatch('user permissions v2', this.property.subdomain)
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
    },
    async save_member_permission(user) {
      try {
        const r = await this.$http.post(`/v2/property/${this.property.subdomain}/update_user`, {
          user_permission_id: user.user_permission_id || user.id,
          state: user.state,
        })

        this.$store.dispatch('user permissions v2', this.property.subdomain)
        this.$store.dispatch('users by id', this.property.id)

        if (r?.data?.message != 'ok') throw Error(r?.data?.message || '변경 실패')
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
    },

  },
}
</script>
