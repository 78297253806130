<style lang="sass" scoped>
a
  color: #777
  border-radius: 5px
  transition: background-color 0.2s
  &:hover
    background-color: #f0f0f0
    text-decoration: none
</style>
<template>
  <a href="#" class="text-left mx-1 d-block hover:bg-gray-200 my-1 py-2 font-medium text-gray-800" >
    <slot/>
  </a>
</template>

<script>
export default {
  name: "DropdownItem",
  // met
};
</script>
