<style lang="sass" scoped>
.origin-top-right
  box-shadow: 0 -0.125rem 0.25rem rgba(0,0,0,.03), 0 0.5rem 2rem rgba(0,0,0,.15) !important;

</style>
<template>
  <transition name="dropdown-content">
    <div
      v-if="active"
      class="origin-top-right absolute right-0 mt-1 w-48 bg-white py-1"
      style="transform-origin: top left; position: absolute; left: 0px; width: 12rem; z-index: 100; font-size: 12px; border-radius: 3px"
      @click='click($event)'
    >
      <slot/>
    </div>
  </transition>
</template>

<script>
export default {
  name: "DropdownContent",
  inject: ["sharedState"],
  mounted() {
    // this.$on('close', () => {
    //   console.log('REQ CLOSE')
    // })
  },
  computed: {
    active() {
      return this.sharedState.active;
    }
  },
  methods: {
    click($event) {
      console.log($event)
      this.sharedState.keep = true
    },
  }
};
</script>

<style>
.dropdown-content-enter-active,
.dropdown-content-leave-active {
  transition: all 0.2s;
}
.dropdown-content-enter,
.dropdown-content-leave-to {
  opacity: 0;
  /* transform: translateY(-5px) scale(0.95); */
  transform:  scale(0.95);
  /* transform: translateY(-5px); */
}
</style>
